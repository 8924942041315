import { createEffect, createMemo, createSignal, For, Match, Show, Switch, useContext } from 'solid-js';
import {
	ActivityIndicator,
	Button,
	TextLink,
	Container,
	HorizontalRule,
	Section,
	TextField,
	Label,
	Input,
	Field,
	Select,
	Option,
	Page,
	FieldDescription,
	SectionStepHeading,
	Link,
} from '@troon/ui';
import { createAsync, useAction, useParams, useSubmissions } from '@solidjs/router';
import { createStore } from 'solid-js/store';
import { Grid, GridTwoThirds, GridThird } from '../../../../../components/layouts/grid';
import { gql, mutationAction, useMutation } from '../../../../../graphql';
import { NotFoundContent } from '../../../../../partials/404';
import { AccessProductsCtx } from '../../../../../providers/card';
import { useStripe } from '../../../../../providers/stripe';
import { StripeSubmit } from '../../../../../components/stripe/submit';
import { Elements } from '../../../../../components/stripe/elements';
import { ProductInfo } from '../../../../access/checkout/_components/product-info';
import { Receipt } from '../../../../access/checkout/_components/receipt';
import { ErrorBoundary } from '../../../../../components/error-boundary';
import { useGolfer } from '../../_providers/golfer';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { PromoCodeEntry } from '../../../../access/checkout/_components/promo-code-entry';
import { objectToFormData } from '../../../../../graphql/form-data';
import type { StripeError } from '@stripe/stripe-js';

type Associate = {
	editing: boolean;
	errors?: Array<string>;
	name?: string;
	facilityId?: string;
	publicRate?: string;
};

export default function AssociateAccessCheckout() {
	const params = useParams<{ id: string }>();
	const products = useContext(AccessProductsCtx);
	const [errors, setErrors] = createSignal<StripeError | undefined>();
	const [enteredCode, setEnteredCode] = createSignal(false);
	const golfer = useGolfer();
	const [associate, setAssociate] = createStore<Associate>({
		editing: true,
	});

	const facilities = createAsync(() => getFacilities({}), { deferStream: true });

	const { setAmount, stripe } = useStripe();

	const productInfo = createMemo(() => {
		return products()?.find((card) => card.id === params.id);
	});

	const createIntent = useMutation(setupIntent);
	const runCreateIntent = useAction(createIntent);
	const subs = useSubmissions(createIntent);
	const currentSub = createMemo(() => subs[subs.length - 1]);
	const lastValidSub = createMemo(
		() => subs.findLast((sub) => !sub.pending && !sub.error && !sub.result?.error) ?? subs[subs.length - 1],
	);

	createEffect(() => {
		const total = currentSub()?.result?.data?.subscription.totalAmount;
		if (total) {
			setAmount({ ...total, cents: total.cents! });
		}
	});

	createAsync(
		async () => {
			const productId = params.id;
			if (!productId || associate.editing || associate.errors?.length) {
				return;
			}

			const data = objectToFormData({
				productId,
				associateInfo: {
					associateName: associate.name,
					facilityId: associate.facilityId,
					publicRate: associate.publicRate,
					operatorId: golfer.operatorId,
					rewardsId: golfer.rewardsId,
				},
			});

			await runCreateIntent(data);
		},
		{ deferStream: false },
	);

	return (
		<Show when={productInfo()} fallback={<NotFoundContent withHero />}>
			{(productInfo) => (
				<>
					<div class="-mb-6 -mt-8 md:-mb-12 lg:hidden">
						<ProductInfo product={productInfo()} />
					</div>

					<Container>
						<Page>
							<Grid>
								<GridTwoThirds class="flex flex-col gap-12">
									<h1 class="sr-only text-4xl font-semibold md:not-sr-only">Complete purchase</h1>

									<Section>
										<SectionStepHeading step={1} state="completed">
											Verify User Information
										</SectionStepHeading>
										<div class="flex grow flex-wrap justify-between">
											<div class="min-w-64 grow">
												<ul class="flex flex-col gap-1">
													<li>
														{golfer.firstName} {golfer.lastName}
													</li>
													<li>{golfer.email}</li>
													<li>{golfer.rewardsId}</li>
												</ul>
											</div>
										</div>
									</Section>

									<Section>
										<SectionStepHeading step={2} state={associate.editing ? 'current' : 'completed'}>
											Your Information
										</SectionStepHeading>

										<Show
											when={associate.editing}
											fallback={
												<ul class="flex flex-col gap-1">
													<li>{associate.name}</li>
													<li>
														{facilities()?.facilities.facilities.find((f) => f.id === associate.facilityId)?.name}
													</li>
												</ul>
											}
										>
											<p>We’ll use this to keep track of credits that you earn for signing users up.</p>

											<form
												onSubmit={(e) => {
													e.preventDefault();
													const data = new FormData(e.currentTarget);

													const name = data.get('associateName') as string;
													const facilityId = data.get('facilityId') as string;
													const publicRate = (data.get('publicRate') as string) || undefined;

													const errors: Array<string> = [];
													if (!name.trim().length) {
														errors.push('Please enter your name.');
													}
													if (!facilityId.trim().length) {
														errors.push('Please select your Troon Facility.');
													}

													setAssociate({
														editing: errors.length !== 0,
														errors: errors.length ? errors : undefined,
														name,
														facilityId,
														publicRate,
													});
												}}
												class="flex flex-col justify-start gap-8"
											>
												<TextField name="associateName">
													<Label>Your name</Label>
													<Input value={golfer.operatorName} />
													<FieldDescription>
														If this name doesn't look right, please make sure you are logged into your own account on
														Troon Operator.
													</FieldDescription>
												</TextField>

												<Field name="facilityId">
													<Label>Troon Facility</Label>
													<Select>
														<For each={facilities()?.facilities.facilities}>
															{(facility) => (
																<Option value={facility.id} selected={facility.id === golfer.facilityId}>
																	{facility.name}
																</Option>
															)}
														</For>
													</Select>
												</Field>

												<TextField name="publicRate">
													<Label>Current Tee Time Public Rate</Label>
													<Input inputMode="numeric" type="number" placeholder="$" />
													<FieldDescription>
														<p>
															If the golfer has a reservation, put the public rate here. It will be recorded and give
															you the Troon Access rate.
														</p>
													</FieldDescription>
												</TextField>

												<Show when={associate.errors}>
													<ul aria-live="assertive">
														<For each={associate.errors}>{(issue) => <li class="text-red-500">{issue}</li>}</For>
													</ul>
												</Show>

												<Button type="submit" class="size-fit">
													Continue
												</Button>
											</form>
										</Show>
									</Section>

									<Section>
										<SectionStepHeading step={3} state={associate.editing ? 'waiting' : 'current'}>
											Payment information
										</SectionStepHeading>

										<Show
											when={
												!currentSub()?.result?.data ||
												(currentSub()?.result?.data?.subscription?.totalAmount &&
													currentSub()?.result?.data?.subscription?.totalAmount?.value > 0)
											}
											fallback={<p>Payment is not required at this time.</p>}
										>
											<ErrorBoundary>
												<Show when={stripe.state !== 'errored'}>
													<div classList={{ hidden: associate.editing }}>
														<Elements />
													</div>
												</Show>
											</ErrorBoundary>
										</Show>
									</Section>
								</GridTwoThirds>

								<GridThird>
									<div class="overflow-y-hidden border-neutral lg:rounded lg:border">
										<div class="hidden overflow-hidden lg:block">
											<ProductInfo product={productInfo()} />
										</div>

										<Show when={!associate.editing}>
											<div data-testId="receipt" class="flex flex-col gap-6 py-6 lg:p-6">
												<Switch fallback={<ActivityIndicator />}>
													<Match when={lastValidSub()?.result?.error?.graphQLErrors}>
														{(errors) => (
															<For each={errors()}>
																{(error) => (
																	<p class="text-red-500" aria-live="assertive">
																		{error.message}
																	</p>
																)}
															</For>
														)}
													</Match>
													<Match when={lastValidSub()?.result?.data?.subscription}>
														{(subscription) => (
															<>
																<PromoCodeEntry
																	action={createIntent}
																	// @ts-expect-error TODO not sure why this is complaining
																	document={query}
																	promoCode={subscription().promoCode as string | undefined}
																	onUpdatePromoCode={(code) => setEnteredCode(!!code)}
																	intentData={{
																		productId: params.id,
																		associateInfo: {
																			associateName: associate.name ?? '',
																			facilityId: associate.facilityId ?? '',
																			rewardsId: golfer.rewardsId,
																			publicRate: associate.publicRate,
																		},
																	}}
																/>
																<Receipt
																	discount={subscription().discountAmount}
																	subtotal={subscription().subtotalAmount}
																	tax={subscription().taxAmount}
																	total={subscription().totalAmount}
																	promoCode={subscription().promoCode}
																/>
															</>
														)}
													</Match>
												</Switch>

												<HorizontalRule />

												<Show when={lastValidSub()?.result?.data?.subscription}>
													{(subscription) => <p class="text-sm text-neutral-800">{subscription()?.disclosure}</p>}
												</Show>

												<Show
													when={
														currentSub()?.result?.data?.subscription?.totalAmount &&
														currentSub()?.result?.data?.subscription?.totalAmount?.value > 0
													}
													fallback={
														<Button
															as={Link}
															href={`/_/associate/access/checkout/confirm?subscriptionId=${currentSub()?.result?.data?.subscription.troonCardSubscriptionId}&productId=${params.id}`}
														>
															Subscribe
														</Button>
													}
												>
													<StripeSubmit
														productId={params.id}
														redirectPath="/_/associate/access/checkout/confirm"
														disabled={
															(enteredCode() && !currentSub()?.result?.data?.subscription.promoCode) ||
															currentSub()?.pending ||
															!!currentSub()?.result?.error
														}
														onError={(errors) => setErrors(errors)}
														stripeClientSecret={currentSub()?.result?.data?.subscription.stripeClientSecret}
														stripeClientSecretIntentType={
															currentSub()?.result?.data?.subscription.stripeClientSecretIntentType
														}
														troonCardSubscriptionId={currentSub()?.result?.data?.subscription.troonCardSubscriptionId}
													>
														Subscribe & Pay
													</StripeSubmit>
												</Show>

												<Show when={errors()}>
													{(error) => (
														<p class="text-red-500" aria-live="assertive">
															There was a problem completing the request: {error().message}
														</p>
													)}
												</Show>

												<p class="text-center text-sm">
													By clicking “Subscribe & pay”, you are agreeing to Troon’s{' '}
													<TextLink target="_blank" href="/access/terms">
														Terms of Service
													</TextLink>{' '}
													and{' '}
													<TextLink target="_blank" href="/privacy-policy">
														Privacy Policy
													</TextLink>
													.
												</p>
											</div>
										</Show>
									</div>
								</GridThird>
							</Grid>
						</Page>
					</Container>
				</>
			)}
		</Show>
	);
}

const facilityQuery = gql(`query accessFacilities {
	facilities: facilitiesV3(sortBy: name, sortDirection: asc) {
		facilities { id, troonFacilityId, name }
	}
}`);

const getFacilities = cachedQuery(facilityQuery);

const query = gql(`mutation setupTroonCardSubscriptionByAssociate(
	$associateInfo: TroonCardAssociateInfoDto!
  $productId: String!
	$promoCode: String
) {
  subscription: setupTroonCardSubscription(
		troonCardAssociateInfo: $associateInfo
    troonCardSubscriptionProductId: $productId
    promoCode: $promoCode
  ) {
  	stripeClientSecret
		stripeCustomerId
		subscriptionName
		troonCardSubscriptionId
		stripeClientSecretIntentType
		promoCode
		disclosure
		totalAmount { displayValue, value, code, cents }
		subtotalAmount { displayValue, value }
		discountAmount { displayValue, value }
		taxAmount { displayValue, value }
  }
}`);

const setupIntent = mutationAction(query);
